<template>
    <b-overlay :show='showLoader'>
        <div v-if="news">
            <b-row v-if="!news.active">
                <b-col>
                    <b-alert variant="danger" class="p-1 text-center w-100" show><b>Ta novica ni aktivna! Vidijo jo lahko samo administratorji, navadni uporabniki do nje nimajo dostopa!</b></b-alert>
                </b-col>
            </b-row>
            <BlueBackground style="top: 4rem;"/>
            <div class="h-100 w-100 position-relative d-none d-md-block" >
                <div class="position-relative p-2" style="z-index: 3;">
                    <div class="title-section">
                        <h1 class="title">{{news.title}} </h1>
                        <b-img class="m-1" style="position: absolute; bottom: 2rem;" :src="require('@/assets/svg/arrow-down-blue.svg')"/>
                    </div>
                </div>
                <b-img class="main-photo" :src="'/api/user/v1/image/' + news.main_photo"/>
            </div>

            <div class="position-relative d-md-none" style="z-index: 2 !important;">
                <div class="title-section-mobile">
                    <h1 class="title-mobile">{{news.title}}</h1>
                </div>
                <div class="d-flex justify-content-center" style="z-index: 3 !important;">
                    <b-img style="max-height: 500px" fluid :src="'/api/user/v1/image/' + news.main_photo"/>
                </div>
            </div>

            <b-row>
                <b-col cols="12" md="10" offset-md="1">
                    <div class="mt-5 mt-md-3 content-font">
                        <div class="html-display" v-html="news.content"/>
                    </div>
                    <b-row class="mt-2" v-if="photos.length > 0">
                        <b-col cols="12" class="d-flex justify-content-center">
                            <b-img style="object-fit: cover;" fluid :src="'/api/user/v1/image/' + photos[0]"/>
                        </b-col>
                        <b-col cols="12" md="6" class="mt-1" v-for="(item, index) of photos" :key="'photo_'+ index">
                            <b-img v-if="photos.length !== index + 1" style="width: 100%; object-fit: cover;" fluid :src="'/api/user/v1/image/' + photos[index + 1]"/>
                        </b-col>
                    </b-row>
                    <div class="mt-2 d-flex justify-content-center">
                        <advertisement-card/>
                    </div>
                    <div v-if="agent" class="mt-5 d-flex justify-content-center">
                    <!--                        <Contact class="col-12 col-md-8 col-lg-6 p-0" :contactData="agent" />--> <!--forma za kontakitranje (pod novico)-->
                    </div>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center align-items-center">
                <a class="d-flex justify-content-center align-items-center" @click="$router.push('/novice'); $scrollToTop()">
                    <div class="mr-2 text-secondary" style="font-weight: 700;font-size: 17px;">Vse novice</div>
                    <img :src="require('@/assets/svg/circle-arrow-right.svg')"/>
                </a>
            </div>
        </div>
    </b-overlay>
</template>

<script>

    import {BCol, BRow, BOverlay, BAlert, BImg} from 'bootstrap-vue'
    import AdvertisementCard from '../../Components/AdvertisementCard'
    //import Contact from '../../Components/Contact'
    import BlueBackground from '@/views/Components/BlueBackground'

    export default {
        components: {
            BlueBackground,
            BCol,
            BRow,
            BOverlay,
            BAlert,
            BImg,
            AdvertisementCard
            //Contact
        },
        data() {
            return {
                showLoader: false,
                news: null,
                created: false,
                photos: [],
                agent: {email: ''}, // za agenta/upravnika se ni maila kam bi se sporocilo poslalo
                news_id: null
            }
        },
        methods: {
            setBreadcrumbTitle(title) {
                const breadCrumbIndex = this.$route.meta.breadcrumb.findIndex(x => x.isCustomBreadcrumb)
                
                if (breadCrumbIndex !== -1) {
                    this.$route.meta.breadcrumb[breadCrumbIndex].text = title

                    this.$root.$emit('force-update-breadcrumbs')
                } 
            },
            async loadSingleNews() {
                const thisIns = this
                thisIns.showLoader = true
                this.photos = []

                this.$scrollToTop()
                try {

                    const dataNews = await thisIns.$http.get(`/api/user/v1/news/${this.news_id}`)
                    this.news = dataNews.data

                    for (const item of this.news.additional_photos) {
                        if (item !== this.news.main_photo) this.photos.push(item)
                    }
                    
                    this.setBreadcrumbTitle(this.news.title)
                    this.created = true
                } catch (error) {
                    thisIns.$printError(`Prišlo je do napake pri nalaganju podatkov\n${error.message}`)
                } finally {
                    thisIns.showLoader = false
                }
            }
        },
        async mounted() {
            this.news_id = this.$route.params.news_id
            await this.loadSingleNews()
        },
        beforeRouteLeave(to, from, next) {
            // before leaving news page, reset breadcrumb title to empty
            this.setBreadcrumbTitle('')
            next()
        }
    }
</script>

<style scoped>
.content-font{
    font-size: 17px;
}

.main-photo {
  position: absolute;
  top: 0;
  right: 0;
  width: 70%;
  height: 100%;
  object-fit: cover;
  z-index: 2;
}
.title-section{
  background-color: rgba(255, 255, 255, 0.88);
  min-height: 400px;
  height: auto;
  padding-bottom: 95px;
  max-width: 35%;
  z-index: 3;
}
.title-section-mobile {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.88);
  bottom: -3.5rem;
  z-index: 3;
}
.title{
  padding: 1rem;
  font-weight: 700;
  font-size: 38px;
  line-height: 48px;
  color: black;
}
.title-mobile{
  padding: 1rem;
  font-weight: 700;
  font-size: 1.4rem;
  color: black;
}
</style>